import { FC, useEffect, useState } from 'react';

import GTMTag from "@apps/gtm/shared/multilabel/src/GTMTag";
import env from '@common/env';
import { useSelfServiceAccount } from '@dxp-auth-be';
import { Script } from '@dxp-next';
import {
  InitialDataLayer,
  getDataLayerEnvironment,
  BeClientDataLayer,
} from '@tracking';

const initialDataLayer: InitialDataLayer = {
  platformVersion: 4,
};

const GTMBe: FC = () => {
  const ENABLE_SERVER_SIDE_GTM = env('ENABLE_SERVER_SIDE_GTM');

  const { selectedAccount } = useSelfServiceAccount();
  const [initialEvent, setInitialEvent] = useState<BeClientDataLayer | null>(null);

  useEffect(() => {
    if (selectedAccount) {
      const event = {
        customerId: selectedAccount.crmAccountNumber,
        role: selectedAccount.role,
      };

      // the initial event should only get set when its first loaded or when the accountId changes
      if (!initialEvent) {
        setInitialEvent(event);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  useEffect(() => {
    if (initialEvent) {
      window.dataLayer.push({
        ...initialEvent,
        environment: getDataLayerEnvironment(),
      });
    }
  }, [initialEvent]);

  if (!ENABLE_SERVER_SIDE_GTM) {
    return <GTMTag customerId={undefined} customerIdEncoded={undefined} accountId={null} />;
  }

  if(!selectedAccount){
        return null;
  }

  const content = [
    'window.dataLayer = window.dataLayer || []',
    `window.dataLayer.push(${JSON.stringify(
      Object.assign(
        {
          customerId: selectedAccount?.crmAccountNumber,
          role: selectedAccount?.role,
          environment: getDataLayerEnvironment(),
        },
        initialDataLayer,
      ),
    )})`,
  ].join(';');


  return (
    <Script strategy="afterInteractive" id="gtm-start">
        {content}
      </Script>
  );
};

export default GTMBe;
